
import { Button, IconButton } from '@mui/material';
import Page from '../Page';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from './Sidebar';
import { connect } from 'react-redux';
import Portal from './Portal';
import AdminPortal from './AdminPortal';
import swal from 'sweetalert';
import { hideLoading, showLoading } from '../../loading';
import actions from '../../redux/actions';
import { USER_ROLES } from '../../../../shared/constants';
import TeacherPortal from './TeacherPortal';
import ParentPortal from './ParentPortal';
import StudentPortal from './StudentPortal';
import { MetaTags } from 'react-meta-tags';
import MetaTitle from '../../components/MetaTitle';
import UserThumbnail from './UserThumbnail';
import PasswordEditor from './PasswordEditor';
import ProfileEditor from './ProfileEditor';


class DashboardUnconnected extends Page {

   state = {
      sidebarOpen: false,
      sidebarValue: undefined,
      loadingUserProfile: false,
      showPasswordEditor: false,
      showProfileEditor: false,
   }

   openSidebar = () => {
      return this.updateState({ sidebarOpen: true });
   }

   closeSidebar = () =>{
      return this.updateState({ sidebarOpen: false });
   }

   getProfile = async () => {

      try {

         showLoading();
         await actions.fetchUser();
         
      } catch (err) {
         swal(err.toString())
      } finally {
         hideLoading();
      }
   }
   
   getPortal() {
      switch (this.props.role) {
         case USER_ROLES.ADMIN:
            return AdminPortal;
         case USER_ROLES.TEACHER:
            return TeacherPortal;
         case USER_ROLES.PARENT:
            return ParentPortal;
         case USER_ROLES.STUDENT:
            return StudentPortal;
         default:
            return Portal;
      }
   }

   setDefaultSidebarValue() {
      const Portal = this.getPortal();
      const sidebarValue = Portal.sidebarItems[0].value;
      return this.updateState({ sidebarValue });
   }

   componentDidMount() {
      super.componentDidMount();

      if (!this.props.authenticated) {
         return setTimeout(() => {
            window.App.redirect('/login');
         }, 50);;
      }

      this.getProfile();
      this.setDefaultSidebarValue();
   }

   _render() {

      let pageContent;

      if (!this.props.user) {
         pageContent = <div className='page-height flex justify-center items-center'>
            <div>
               <p className='text-lg text-gray-500'>
                  Failed to load user profile
               </p>
               <Button fullWidth onClick={this.getProfile}>
                  Retry
               </Button>
            </div>
         </div>
      } else {

         let CurrentPortal = this.getPortal();

         const sidebar = <Sidebar
            active={this.state.sidebarValue}
            onChange={
               async sidebarValue => {
                  await this.updateState({ sidebarValue });
                  await this.closeSidebar();
               }
            }
            openPasswordEditor={
               async () => {
                  await this.closeSidebar();
                  this.setState({ showPasswordEditor: true })
               }
            }
            openProfileEditor={
               async () => {
                  await this.closeSidebar();
                  this.setState({ showProfileEditor: true })
               }
            }
            user={this.props.user}
            items={CurrentPortal.sidebarItems}
         />;

         let mobileSidebar, passwordEditor, profileEditor;

         if (this.state.sidebarOpen) {
            mobileSidebar = <div className='fixed top-0 left-0 h-screen w-screen z-[5000] md:hidden'>
               {sidebar}
            </div>
         }

         if (this.state.showPasswordEditor) {
            passwordEditor = <PasswordEditor
               close={() => this.setState({ showPasswordEditor: false })}
            />
         }

         if (this.state.showProfileEditor) {
            profileEditor = <ProfileEditor
               close={() => this.setState({ showProfileEditor: false })}
               data={this.props.user}
            />
         }

         pageContent = (
            <div className='page-height md:grid grid-cols-[auto,1fr]'>

               <div className='hidden md:block'>
                  {sidebar}
               </div>

               {mobileSidebar}
               {passwordEditor}
               {profileEditor}

               <div className='h-full overflow-hidden grid grid-rows-[auto,1fr] p-2 z-30'>

                  <div className='py-3 relative border-b-[1px] border-b-[#ccc]'>
                     <div className='md:hidden absolute right-0 top-0 bottom-0 flex items-center'>
                        <IconButton onClick={this.openSidebar}>
                           <MenuIcon />
                        </IconButton>
                     </div>

                     <div className='md:grid md:grid-cols-[1fr,auto]'>
                        <div className='text-gray-500 text-2xl font-bold uppercase flex items-center'>
                           {CurrentPortal.title}
                           {
                              this.props.user?.is_demo && <span className='inline-block ml-2 rounded-xl shadow text-xs text-white bg-[var(--secondary)] py-1 px-2'>
                                 DEMO
                              </span>
                           }
                        </div>
                        <div className='hidden md:grid'>
                           <UserThumbnail />
                        </div>
                     </div>
                  </div>
                  
                  <div className='overflow-y-auto'>
                     <CurrentPortal 
                        user={this.props.user}
                        sidebarValue={this.state.sidebarValue}
                        changeSidebarValue={sidebarValue => this.updateState({ sidebarValue })}
                     />
                  </div>
                  
               </div>

            </div>
         );
      }

      return <>
			<MetaTags>
            <MetaTitle>Dashboard</MetaTitle>
          </MetaTags>
          {pageContent}
      </>
  }
}

const mapStateToProps = state => {
   return {
      user: state.user,
      role: state.role,
      authenticated: state.authenticated,
   }
}

const Dashboard = connect(mapStateToProps)(DashboardUnconnected);
export default Dashboard;