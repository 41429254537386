import { Button, Divider } from "@mui/material";
import { Component } from "react";
import NumberCard from "./NumberCard";
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
import StartIcon from '@mui/icons-material/PlayCircle';
import FeesEditor from "./FeesEditor";
import SchoolContext from "../SchoolContext";
import RecordPayment from "./RecordPayment";
import PrefixEditor from "./PrefixEditor";
import Subscription from "./Subscription";
import { NewTerm } from "./NewTerm";
import TeacherIcon from '@mui/icons-material/Person2';
import StudentIcon from '@mui/icons-material/School';
import ClassIcon from '@mui/icons-material/Groups';
import FeesIcon from '@mui/icons-material/Paid';
import { connect } from "react-redux";



function SectionTitle({ title, subtitle }) {
   return <div>
      <div className="text-lg font-bold text-gray-600">{title}</div>

      {
         subtitle && <p className="mt-1 text-gray-500 border-l-[4px] border-l-gray-400 py-1 px-2 text-sm">
            {subtitle}
         </p>
      }
   </div>
}


class DashboardUnconnected extends Component {

   static contextType = SchoolContext;

   state = {
      feesEditorOpen: false,
      paymentRecorderOpen: false,
      newTermEditorOpen: false,
   }

   canStartNewTerm = () => {
      if (!this.context.school.fees)
         return false;
      return true;
   }

   openFeesEditor = async () => {
      return this.updateState({ feesEditorOpen: true });
   }

   closeFeesEditor = async () => {
      return this.updateState({ feesEditorOpen: false });
   }

   openNewTermEditor = () => {
      this.setState({ newTermEditorOpen: true })
   }

   closeNewTermEditor = (current_term) => {
      if (current_term)
         this.context.updateSchool({ current_term });

      this.setState({ newTermEditorOpen: false });
   }

   openPaymentRecorder = () => { 
      return this.updateState({ paymentRecorderOpen: true });
   }

   closePaymentRecorder = () => {
      return this.updateState({ paymentRecorderOpen: false });
   }

   getYearLastThreeDigits() {
      return new Date().getFullYear().toString().slice(-3);
   }

   render() {

      const divider = <Divider className="my-4" />
      
      let setFeesIcon, recordPaymentIcon, startTermIcon;
      const isXS = this.props.device.size === 'xs';

      if (!isXS) {
         setFeesIcon = <PriceChangeOutlinedIcon />
         recordPaymentIcon = <PaymentIcon />
         startTermIcon = <StartIcon />
      }

      return (
         <div className="p-4">

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 py-4">
               <NumberCard
                  number={this.context.school.students}
                  text="Students"
                  icon={StudentIcon}
               />

               <NumberCard
                  number={this.context.school.classes}
                  text="Classes"
                  icon={ClassIcon}
               />

               <NumberCard
                  number={this.context.school.teachers}
                  text="Teachers"
                  icon={TeacherIcon}
               />

               {
                  this.context.school.fees && <NumberCard
                     number={this.context.school.fees}
                     text="Fees"
                     icon={FeesIcon}
                  />
               }
            </div>

            {divider}

            <div className="my-8">

               <SectionTitle
                  title={"SCHOOL FEES"}
                  subtitle={"Set school fees for the term, start a new term, record fees payments."}
               />

               <div className="mt-4 grid gap-4 grid-cols-2 sm:inline-grid sm:grid-cols-3 [&>*]:rounded-full">

                  <Button
                     variant="contained"
                     startIcon={recordPaymentIcon}
                     onClick={this.openPaymentRecorder}
                  >
                     {!isXS && "RECORD"} PAYMENT
                  </Button>

                  <Button
                     variant="contained"
                     className="text-[var(--primary)] bg-blue-50 hover:bg-blue-100"
                     disableElevation
                     startIcon={startTermIcon}
                     onClick={this.openNewTermEditor}
                     disabled={!this.canStartNewTerm()}
                  >
                     {!isXS && "START"} NEW TERM
                  </Button>

                  <Button
                     variant="outlined"
                     startIcon={setFeesIcon}
                     onClick={this.openFeesEditor}
                  >
                     SET FEES
                  </Button>

                  {
                     this.state.feesEditorOpen && <FeesEditor close={this.closeFeesEditor} />
                  }

                  {
                     this.state.paymentRecorderOpen && <RecordPayment close={this.closePaymentRecorder} />
                  }

                  {
                     this.state.newTermEditorOpen && <NewTerm
                        close={this.closeNewTermEditor}
                        currentTerm={this.context.school.current_term}
                     />
                  }

               </div>
            </div>

            {divider}

            <div className="my-8">

               <SectionTitle
                  title={"ID PREFIXES"}
                  subtitle={"These prefixes will be used to generate student and teacher numbers that can be used school IDs, as illustrated below"}
               />

               <div className="mt-4 p-4 bg-gray-100 rounded text-gray-600 uppercase">
                  <p>
                     <b>Student No</b>: {this.context.school.student_no_prefix}{this.getYearLastThreeDigits()}0001
                  </p>
                  <p>
                     <b>Teacher No</b>: {this.context.school.teacher_no_prefix}{this.getYearLastThreeDigits()}0002
                  </p>
               </div>

               <div className="[&>*]:max-w-[400px] [&>*]:mt-4">
                  <div>
                     <PrefixEditor
                        mode={PrefixEditor.MODES.STUDENT}
                     />
                  </div>
                  <div>
                     <PrefixEditor
                        mode={PrefixEditor.MODES.TEACHER}
                     />
                  </div>
               </div>
            </div>

            {divider}

            <div className="my-8">
               <Subscription />
            </div>

         </div>
      );
   }
}

const mapStateToProps = state => ({ device: state.device });
const Dashboard = connect(mapStateToProps)(DashboardUnconnected);
export default Dashboard;