import HomeworkTestList from "./HomeworkTestList";
import TabPanelPagination from "./TabPanelPagination";
import PMessage from "./PMessage";
import TabPanel from "./TabPanel";
import HomeworkTestThumbnail from '../../../HomeworkTest/Thumbnail';
import AddIcon from "@mui/icons-material/Add";
import { Component } from "react";
import { IconButton } from "@mui/material";
import HomeworkEditor from "../../../HomeworkTest/HomeworkEditor";
import EmptyState from "../../../../../components/EmptyState";


export default class Homework extends Component {

	state = {
		homeworkEditorOpen: false,
	}

	addHomework = () => {
		this.setState({ homeworkEditorOpen: true });
	}

	render() {

		const { homework, loading, error, onRefresh, onDismissError, page, count, pageSize, onPageChange } = this.props;
		let homeworkJSX;


		if (homework) {
			if (homework.length > 0) {
				homeworkJSX = <HomeworkTestList>
					{
						homework.map(homework => {
							return <HomeworkTestThumbnail {...homework} isHomework onDelete={onRefresh} />
						})
					}
				</HomeworkTestList>
			} else {
				homeworkJSX = <div className="h-[500px]">
					<EmptyState
						title="No items"
						subtitle="No homeworks recorded"
						onAddButtonClicked={this.addHomework}
					/>
				</div>
			}
		} else {
			homeworkJSX = <PMessage>
				Homeworks not loaded
			</PMessage>
		}

		const footer = <div className="grid grid-cols-[1fr,auto]">
			<TabPanelPagination
				itemCount={count}
				pageSize={pageSize}
				page={page}
				onChange={onPageChange}
				fetch={onRefresh}
			/>

			<IconButton size="small" onClick={this.addHomework}>
				<AddIcon />
			</IconButton>

			{
				this.state.homeworkEditorOpen && <HomeworkEditor
					close={() => {
						this.setState({ homeworkEditorOpen: false });
						this.props.onAdd();
					}}
				/>
			}
		</div>

		return <TabPanel
			heading="Homework"
			body={homeworkJSX}
			loading={loading}
			error={error}
			onRefresh={onRefresh}
			onDismissError={onDismissError}
			footer={footer}
			loaded={!!homework}
		/>
	}
	
}