import HomeworkTestList from "./HomeworkTestList";
import TabPanelPagination from "./TabPanelPagination";
import TabPanel from "./TabPanel";
import HomeworkTestThumbnail from '../../../HomeworkTest/Thumbnail';
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Component } from "react";
import TestEditor from "../../TestEditor";
import EmptyState from "../../../../../components/EmptyState";
import CenteredMessage from "../../../../../components/CenteredMessage";

export default class Tests extends Component {

	state = {
		testEditorOpen: false,
	}

	addTest = () => {
		this.setState({ testEditorOpen: true });
	}

	render() {

		const { tests, loading, error, onRefresh, onDismissError, page, count, pageSize, onPageChange } = this.props;
		let testsJSX;

		if (tests) {
			if (tests.length > 0) {
				testsJSX = <HomeworkTestList>
					{
						tests.map(test => {
							return <HomeworkTestThumbnail {...test} onDelete={onRefresh} />
						})
					}
				</HomeworkTestList>
			} else {
				testsJSX = <div className="h-[500px]">
					<EmptyState
						title="No tests"
						subtitle="No tests recorded"
						onAddButtonClicked={this.addTest}
					/>
				</div>
			}
		} else {
			testsJSX = <div className="h-[500px]">
				<CenteredMessage
					message={"Tests not loaded"}
					onRefresh={onRefresh}
				/>
			</div>
		}

		const footer = <div className="grid grid-cols-[1fr,auto]">
			<TabPanelPagination
				itemCount={count}
				pageSize={pageSize}
				page={page}
				onChange={onPageChange}
				fetch={onRefresh}
			/>

			<IconButton size="small" onClick={this.addTest}>
				<AddIcon />
			</IconButton>

			{
				this.state.testEditorOpen && <TestEditor
					close={() => { 
						this.setState({ testEditorOpen: false });
						this.props.onAdd();
					}}
				/>
			}
		</div>

		return <TabPanel
			heading="Tests"
			body={testsJSX}
			loading={loading}
			error={error}
			onRefresh={onRefresh}
			onDismissError={onDismissError}
			footer={footer}
			loaded={!!tests}
		/>
	}

}