

import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, MenuItem, TextField } from '@mui/material';
import TeacherContext from '../TeacherContext';
import swal from 'sweetalert';
import { showLoading, hideLoading } from '../../../../loading';
import { connect } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import AttendanceList from './AttendanceList';
import request from '../../../../request';
import CenteredMessage from '../../../../components/CenteredMessage';
import EmptyState from '../../../../components/EmptyState';
import { getClassLevelLabel } from '../../../../../../shared/utils';
import FullscreenDialog from '../../../../components/FullscreenDialog';


const CLASS = 'class';


class RecordAttendanceUnconnected extends React.Component {

	static contextType = TeacherContext;

	state = {
		students: null,
		classSubject: null,
		attendanceList: [],
	}

	fetchClassSubjects = async () => {
		try {
			showLoading();
			await this.context.fetchClassSubjects();
		} catch (err){
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	fetchStudents = async () => {
		try {

			showLoading();
			
			let students;

			const classSubjectId = this.state.classSubject;

			if (classSubjectId === CLASS) {
				const classId = this.getClassId();
				const res = await request.get('/api/general/students', { params: { class: classId }});
				students = res.data.students;
			} else {
				const res = await request.get(`/api/teacher/class-subjects/${classSubjectId}`);
				students = res.data.students;
			}

			await this.updateState({ students, attendanceList: [] });

		} catch (err){
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	submit = async () => {
		try {

			showLoading();
			
			const data = {
				students: this.state.attendanceList.map(item => item._id),
			};

			const classSubjectId = this.state.classSubject;

			if (classSubjectId !== CLASS)
				data.class_subject = classSubjectId;

			await request.post('/api/teacher/attendances', data)
			await this.props.close();

		} catch (err){
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	getSchoolType() {
		return this.props.user.school.type;
	}

	getClassId() {
		return this.props.user.class?._id
	}

	async componentDidMount() {
		if (!this.context.classSubjects)
			this.fetchClassSubjects();
	}

	render() {

		let studentList;
		const { students, classSubject } = this.state;

		if (!classSubject) {
			studentList = <CenteredMessage
				message={"Students will appear after you class and select the subject"}
			/>
		} else if (!students) {
			studentList = <CenteredMessage
				message={"Students not fetched."}
				onRefresh={this.fetchStudents}
			/>
		} else if (students.length === 0) {
			studentList = <EmptyState
				title='No students'
				subtitle='The student list is empty'
			/>
		} else {
			studentList = <AttendanceList
				students={students}
				refresh={this.fetchStudents}
				attendanceList={this.state.attendanceList}
				onAttendanceListChange={
					(student, present) => {

						let attendanceList;

						if (present)
							attendanceList = [ ...this.state.attendanceList, student ];
						else
							attendanceList = this.state.attendanceList.filter(item => item._id !== student._id);

						return this.updateState({ attendanceList });
					}
				}
			/>
		}

		const { classSubjects } = this.context;
		const schooType = this.getSchoolType();
		const gradeOrForm = getClassLevelLabel(schooType);

		const dialogContent = <div className='h-full grid grid-rows-[auto,1fr] gap-4 pt-4'>
			<div>
				<TextField
					label="Select students"
					variant='outlined'
					size='small'
					onChange={
						async e => {
							await this.updateState({
								classSubject: e.target.value,
								attendanceList: [],
								students: null
							});
							this.fetchStudents();
						}
					}
					select
					fullWidth
				>
					{
						this.getClassId() && <MenuItem value={CLASS}>
							<MenuItemContent
								title={`Class: ${this.props.user.class.name}`}
								subtitle={`Record attendance for students in "${this.props.user.class.name}" class`}
								selected={classSubject === CLASS}
							/>
						</MenuItem>
					}
					{
						classSubjects && classSubjects.map(item => {
							return <MenuItem value={item._id}>
								<MenuItemContent
									title={`Subject: ${item.subject.name} – ${gradeOrForm} ${item.class.level} (${item.class.name}`}
									subtitle={`Record attendance for students attending this subject`}
									selected={classSubject === item._id}
								/>
							</MenuItem>
						})
					}
					{
						!classSubjects && <MenuItem value="">
							<div className='inline-grid grid-cols-[1fr,auto] w-full'>
								<span>
									Subjects not fetched 
								</span>
								<IconButton onClick={this.fetchClassSubjects}>
									<RefreshIcon />
								</IconButton>
							</div>
						</MenuItem>
					}
					{
						classSubjects && ((this.getClassId() ? 1 : 0) + (classSubjects?.length || 0)) === 0 && <MenuItem disabled className='name'>
							You are not assigned to any subject or class
						</MenuItem>
					}
				</TextField>
			</div>

			<div className='overflow-y-auto'>{studentList}</div>

		</div>

		const body = <div className='h-full overflow-y-auto'>
			{dialogContent}
		</div>

		const actions = <Button
			variant='contained'
			size='small'
			onClick={this.submit}
		>
			SUBMIT
		</Button>

		return <FullscreenDialog
			title={"Record attendance"}
			body={body}
			actions={actions}
			close={this.props.close}
		/>

	}
}

function MenuItemContent({ title, subtitle, selected=false }) {
	return <div className='text-gray-600'>
		<div className='text-sm font-bold truncate'>
			{title}
		</div>
		{
			!selected && <div className='text-xs truncate'>
				{subtitle}
			</div>
		}
	</div>
}

const mapStateToProps = state => ({ user: state.user });
const RecordAttendance = connect(mapStateToProps)(RecordAttendanceUnconnected);

RecordAttendance.propTypes = {
	close: PropTypes.func.isRequired
};

export default RecordAttendance;